<script>
import LoaderComponent from './components/LoaderComponent'
export default {
	components: {
		LoaderComponent
	},
	computed: {
		layout() {
			return this.$route.meta.layout ? `${this.$route.meta.layout}Layout`  : 'AppLayout'
		},
		appClassName() {
			if(this.$route.name == 'Contact') {
				return 'bg-brand'
			}
		}
	}
}
</script>
<template>
	<div id="app" :class="appClassName">
		<component :is="layout"></component>
		<LoaderComponent />
	</div>
</template>

<style lang="scss">
  @import '@/assets/sass/style.scss';
</style>>
