import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import axios from 'axios'
import VueAxios from 'vue-axios'
// Or as a directive
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

Vue.use(vueNumeralFilterInstaller, {locale: 'en'})

Vue.use(VueAxios, axios)

Vue.directive('mask', VueMaskDirective);

const registeredLayouts = [
	'App',
	'Simple'
]
  
registeredLayouts.map((layout) => {
	Vue.component(`${layout}Layout`, () => import(/* webpackChunkName: "[request]-layout" */ `./layouts/${layout}Layout.vue`))
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
