<template>
  <div class="home" style="margin-bottom:120px">
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>

<style lang="scss" scope>
	.home{
		padding-bottom: 4rem;
		@media screen and (min-width: 668px) {
			padding-bottom: 0;
		}
	}

</style>
