<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			loader: 'loader',
			messageLoader: 'messageLoader'
		})
	}	
}
</script>

<template>
	<div class="v-loader" v-if="loader" :class="{'v-loader--with-message': messageLoader}">
		<div class="v-loader__img" v-if="!messageLoader">
			<img src="../assets/img/logo.svg">
			<div class="v-loader__img-bg"></div>
			<div class="v-loader__text">Cargando</div>
		</div>
		<div class="v-loader__spinner" v-else>
			<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			<div class="v-loader__spinner-text">{{messageLoader}}</div>
		</div>
	</div>
</template>
