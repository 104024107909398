import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
	children: [
		{
			path: '',
			name: 'HomeIndex',
			component:  () => import(/* webpackChunkName: "HomeIndex" */ '../views/Home/HomerIndex.vue'),
			meta: {
				prevStep: null,
				nextStep: 'Sides',
			}
		},
		{
			path: '/complementos',
			name: 'Sides',
			component:  () => import(/* webpackChunkName: "Sides" */ '../views/Home/SidesView.vue'),
			meta: {
				prevStep: 'HomeIndex',
				nextStep: 'Drinks',
			}
		},
		{
			path: '/bebidas',
			name: 'Drinks',
			component:  () => import(/* webpackChunkName: "Drinks" */ '../views/Home/DrinksView.vue'),
			meta: {
				prevStep: 'Sides',
				nextStep: 'Payment',
			}
		},
		{
			path: '/pago',
			name: 'Payment',
			component:  () => import(/* webpackChunkName: "Paymet" */ '../views/Home/PaymentView.vue'),
			meta: {
				prevStep: 'Drinks',
				nextStep: 'Payment',
			}
		}
	]
  },
  {
    path: '/events',
    name: 'Events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Events" */ '../views/Events.vue'),
	meta: {
	}
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
	meta: {
	}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
