import Vue from 'vue'
import Vuex from 'vuex'
import {$get} from '../http/index'
Vue.use(Vuex)


const store = new Vuex.Store(
	{
		state: {
			loading: 0,
			messageLoader: null,
			cart: {
				hotdogs: [],
				sides: [],
				drinks: [],
			},
			menu: {
				hotdogs: [],
				sides: [],
				drinks: [],
			},
			zones: [
				{
					name: '75 Avenida',
					shippinRule: 1,
					value:3.50
				},
				{
					name: 'Ant. Cuscatlán',
					shippinRule: 1,
					value:2.50
				},
				{
					name: 'Ave. Bernal',
					shippinRule: 1,
					value:3.50
				},
				{
					name: 'Ayutuxtepeque',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Ciudad Dorada',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Cond. Miramar',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'El Encanto',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Flor Blanca',
					shippinRule: 1,
					value:2.50
				},
				{
					name: 'La Cima',
					shippinRule: 1,
					value:3.50
				},
				{
					name: 'Lourdes',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Mejicanos',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Nvo. Cuscatlán',
					shippinRule: 1,
					value:2.50
				},				{
					name: 'Res. La Hacienda',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Res. Las Luces',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Santa Elena',
					shippinRule: 1,
					value:2.50
				},
				{
					name: 'Soyapango (C.C. y gasolineras)',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Tuscania',
					shippinRule: 1,
					value:4.50
				},
				{
					name: 'Zaragoza',
					shippinRule: 1,
					value:4.50
				},
			]
		
		},
		actions: {
			async getMenu({commit}, type = 'hotdogs') {
				commit('addLoader')
				try {
					let {data} = await $get[type]()
					commit('addToMenu', {type, data})
				} catch (error) {
					console.log(error)
				}
				commit('romoveLoader')
			},
		},
		mutations: {
			addLoader(state, payload = null) {
				state.loading = state.loading + 1
				state.messageLoader = payload
			},
			romoveLoader(state) {
				state.loading = state.loading - 1
			},
			loading(state, payload) {
				state.loading = payload
			},
			addToMenu(state, payload) {
				
				// Vue.$set(state.menu, payload.type, payload.data)
				state.menu[payload.type] = payload.data
			},

			addToCart(state, payload) {
				payload = JSON.stringify(payload)
				payload = JSON.parse(payload)
				let exist = state.cart[payload.type].find(item => item.id == payload.item.id)
				if(exist) {
					exist.qty++
					return
				}
				payload.item.qty = 1
				state.cart[payload.type].push(payload.item)
			},

			removeToCart(state, payload) {
				let existIndex = null
				let exist = state.cart[payload.type].find((item, i) => {
					existIndex = i
					return item.id == payload.id
				})

				if(!exist) {
					return
				}

				exist.qty--

				if(exist.qty) {
					return
				}
				
				state.cart[payload.type].splice(existIndex, 1)
			},

			updateCart(state, payload) {
				payload = JSON.stringify(payload)
				payload = JSON.parse(payload)
				let existIndex = null
				let exist = state.cart[payload.type].find((item, index) => {
					existIndex = index
					return item.id == payload.item.id
				})
				if(exist) {
					exist.qty = payload.qty
					if(exist.qty) return
					state.cart[payload.type].splice(existIndex, 1)
					return
				} 
				payload.item.qty = payload.qty
				state.cart[payload.type].push(payload.item)
			},

			removeOnCart(state, payload) {
				let index = state.cart[payload.type].findIndex(i => i.id == payload.id)
				if(index >= 0) {
					state.cart[payload.type].splice(index, 1)
				}
			},

			resetCart(state) {
				Vue.set(state, 'cart',{
					hotdogs: [],
					sides: [],
					drinks: [],
				})
			}


		},
		getters: {
			qtyCart: (state) => (id = null, type = 'hotdogs') => {
				if(id) {
					let item = state.cart[type].find(i => i.id == id)
					return item ? item.qty : 0
				}

				return state.cart[type].reduce(((a, b) =>{ 
					a.qty += b.qty 
					return a
				}), {qty: 0}).qty
			},

			totalCart: (state)  => {
				let total = 0

				for(const type in state.cart) {
					let subTotal = 0
					state.cart[type].forEach(item => {
						subTotal += (Number(item.price) * item.qty)
					})

					total += subTotal
				}

				return total
			},

			cart: (state) => {
				return state.cart
			},

			hotdogs: (state) => state.menu.hotdogs,
			sides: (state) => state.menu.sides,
			drinks: (state) => state.menu.drinks,
			loader: (state) => state.loading > 0,
			messageLoader: (state) => state.messageLoader,
			zones: (state) => state.zones
		}
	}
)

export default store