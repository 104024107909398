import Vue from 'vue'
const api = 'https://api.panshito.com/api'


export const $get = {
	hotdogs: async () => {
		const endpoint = `${api}/producto/tipoproducto/1/0`
		try {
			return await Vue.axios.get(endpoint)
		} catch (error) {
			return Promise.reject(error)
		}
	},
	sides: async () => {
		const endpoint = `${api}/producto/tipoproducto/2/0`
		try {
			return await Vue.axios.get(endpoint)
		} catch (error) {
			return Promise.reject(error)
		}
	},
	drinks: async () => {
		const endpoint = `${api}/producto/tipoproducto/3/0`
		try {
			return await Vue.axios.get(endpoint)
		} catch (error) {
			return Promise.reject(error)
		}
	},

	
	
}

export const $post = {
	
	events: async (payload) => {
		const endpoint = `${api}/eventos`
		try {
			return await Vue.axios.post(endpoint, payload)
		} catch (error) {
			return Promise.reject(error)
		}
	},
	
	contact: async (payload) => {
		const endpoint = `${api}/contacto`
		try {
			return await Vue.axios.post(endpoint, payload)
		} catch (error) {
			return Promise.reject(error)
		}
	},

	order: async (payload) => {
		
		const endpoint = `${api}/orden`
		try {
			return await Vue.axios.post(endpoint, payload)
		} catch (error) {
			return Promise.reject(error)
		}
	},



}